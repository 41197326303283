import { IonContent, IonPage } from "@ionic/react";
import { Link, useParams } from "react-router-dom";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "convex/_generated/dataModel";
import { Button } from "@/components/ui/button";
import { ArrowLeft, ArrowRight, CheckCircle, Info } from "lucide-react";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "@/components/ui/tabs";
import { Card } from "@/components/ui/card";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Switch } from "@/components/ui/switch";
import { useEffect, useState } from "react";
import { usePostHog } from "posthog-js/react";

const HabitPage: React.FC = () => {
  const posthog = usePostHog();
  const createPractice = useMutation(api.practices.create);

  const { id } = useParams<{ id: string }>();
  const habit = useQuery(api.habits.getById, { id: id as Id<"habits"> });
  const communityPractices = useQuery(api.communityPractices.get, { habitId: id as Id<"habits"> });

  const [activeTab, setActiveTab] = useState("learn");
  const [practiceTitle, setPracticeTitle] = useState("");
  const [practiceShared, setPracticeShared] = useState(true);

  const localReminderHour = 8;
  const localCheckInHour = 19;

  const reminderDate = new Date();
  reminderDate.setHours(localReminderHour, 0, 0, 0);
  const utcReminderHour = reminderDate.getUTCHours();

  const checkInDate = new Date();
  checkInDate.setHours(localCheckInHour, 0, 0, 0);
  const utcCheckInHour = checkInDate.getUTCHours();

  useEffect(() => {
    posthog.capture("user_viewed_habit", {
      habit: habit?.name,
    });
  }, [habit]);

  const handleCreatePractice = async (title: string, shared: boolean) => {
    posthog.capture("user_added_practice", {
      type: "Custom",
      shared: shared,
      habit: habit?.name,
      title: title,
    });

    await createPractice({
      habitId: id as Id<"habits">,
      title,
      shared,
      reminderHour: utcReminderHour,
      checkInHour: utcCheckInHour,
    });
  };

  const handleAddSuggestedPractice = async (title: string) => {
    posthog.capture("user_added_practice", {
      type: "Suggested",
      shared: false,
      habit: habit?.name,
      title: title,
    });

    await createPractice({
      habitId: id as Id<"habits">,
      title,
      shared: false,
      reminderHour: utcReminderHour,
      checkInHour: utcCheckInHour,
    });
  };

  const handleAddCommunityPractice = async (title: string, practiceId: Id<"communityPractices">) => {
    posthog.capture("user_added_practice", {
      type: "Community",
      shared: false,
      habit: habit?.name,
      title: title,
    });

    await createPractice({
      habitId: id as Id<"habits">,
      communityPracticeId: practiceId,
      title,
      shared: false,
      reminderHour: utcReminderHour,
      checkInHour: utcCheckInHour,
    });
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="p-4 h-full max-w-2xl mx-auto">
          <Button variant="ghost" onClick={() => window.history.back()}>
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </Button>
          {habit ? (
            <div className="p-3 h-full">
              <h1 className="text-2xl font-bold mb-3">{habit.name}</h1>
              <Tabs value={activeTab} onValueChange={setActiveTab}>
                <TabsList className="overflow-x-auto -mx-2" style={{ width: "calc(100% + 1rem)" }}>
                  <TabsTrigger value="learn" className="w-full">
                    <Info className="w-4 h-4 mr-2 text-blue-600" />
                    Learn
                  </TabsTrigger>
                  <TabsTrigger value="practice" className="w-full">
                    <CheckCircle className="w-4 h-4 mr-2 text-green-600" />
                    Practice
                  </TabsTrigger>
                </TabsList>
                <TabsContent value="practice" className="space-y-3 pt-3">
                  <p className="text-sm text-gray-700">{habit.practicesOverview}</p>
                  <h3 className="text-lg font-bold pt-3">Daily Practices</h3>
                  <Tabs defaultValue="choose">
                    <TabsList className="overflow-x-auto -mx-2" style={{ width: "calc(100% + 1rem)" }}>
                      <TabsTrigger className="w-full" value="choose">
                        Choose a practice
                      </TabsTrigger>
                      <TabsTrigger className="w-full" value="create">
                        Create your own
                      </TabsTrigger>
                    </TabsList>
                    <TabsContent value="choose" className="space-y-3 pt-2">
                      <h4 className="text-md font-semibold pl-1 pt-2">Recommended for you</h4>
                      {habit.practices.map((practice) => (
                        <div key={practice._id}>
                          <Link to={`/home`}>
                            <Card
                              className="-mx-1 p-3 px-4 rounded-xl flex items-center justify-between"
                              onClick={() => handleAddSuggestedPractice(practice)}
                            >
                              <p className="text-sm text-gray-700">{practice}</p>
                              <Button variant="ghost" size="icon" className="pl-1">
                                <ArrowRight className="w-5 h-5 text-gray-500" />
                              </Button>
                            </Card>
                          </Link>
                        </div>
                      ))}
                      <h4 className="text-md font-semibold pl-1 pt-4">From the community</h4>
                      {communityPractices?.length ? (
                        communityPractices.map((practice) => (
                          <div key={practice._id}>
                            <Link to={`/home`}>
                              <Card
                                className="-mx-1 p-3 px-4 rounded-xl flex items-center justify-between"
                                onClick={() => handleAddCommunityPractice(practice.title, practice._id)}
                              >
                                <p className="text-sm text-gray-700">{practice.title}</p>
                                <Button variant="ghost" size="icon" className="pl-1">
                                  <ArrowRight className="w-5 h-5 text-gray-500" />
                                </Button>
                              </Card>
                            </Link>
                          </div>
                        ))
                      ) : (
                        <div className="-mx-1 bg-secondary w-full h-16 rounded-xl text-xs flex items-center justify-center">
                          Coming soon...
                        </div>
                      )}
                    </TabsContent>
                    <TabsContent value="create" className="-mx-1 space-y-3 pt-3">
                      <div className="space-y-2">
                        <Textarea
                          id="daily-practice"
                          placeholder="Enter your daily practice..."
                          className="min-h-[80px] bg-white rounded-xl text-sm"
                          value={practiceTitle}
                          onChange={(e) => setPracticeTitle(e.target.value)}
                        />
                        <p className="text-xs text-gray-700 p-1">
                          We encourage people to share their practices to foster community learning and growth. If you choose to
                          share your practice, it will be completely anonymous.
                        </p>
                        <div className="flex items-center justify-between px-1">
                          <Label htmlFor="share-with-community">Share this practice</Label>
                          <Switch id="share-with-community" checked={practiceShared} onCheckedChange={setPracticeShared} />
                        </div>
                        <div className="flex justify-end pt-4">
                          <Link to={`/home`}>
                            <Button
                              onClick={() => handleCreatePractice(practiceTitle, practiceShared)}
                              className="w-full rounded-full"
                            >
                              Create Practice
                            </Button>
                          </Link>
                        </div>
                      </div>
                    </TabsContent>
                  </Tabs>
                </TabsContent>
                <TabsContent value="learn" className="space-y-3 pt-3">
                  <h5 className="text-lg font-semibold">What is {habit.name}?</h5>
                  <p className="text-sm md:text-base text-gray-700">
                    {habit.definition} {habit.description}
                  </p>
                  <h5 className="text-lg font-semibold pt-3">The benefits of {habit.name}</h5>
                  <p className="text-sm md:text-base text-gray-700">{habit.benefitsSummary}</p>
                  <p className="text-sm md:text-base text-gray-700">{habit.benefits}</p>
                  <p className="text-sm md:text-base text-gray-700 pb-2">{habit.cardinalBenefits}</p>
                  <div className="h-[100px]" />
                  <div className="fixed bottom-6 right-0 left-0 w-full px-6">
                    <div className="flex justify-center">
                      <Button
                        size="lg"
                        onClick={() => setActiveTab("practice")}
                        className="rounded-full w-full max-w-xl mx-auto shadow-xl"
                      >
                        Practice {habit.name}
                      </Button>
                    </div>
                  </div>
                </TabsContent>
              </Tabs>
              <div className="h-[100px]" />
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HabitPage;
