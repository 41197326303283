import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { ArrowLeft, ArrowRight, BookCheck, PlusCircle } from "lucide-react";
import { UserButton } from "@clerk/clerk-react";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import PracticeCard from "@/components/PracticeCard";
import { Card } from "@/components/ui/card";
import { useState } from "react";

const introSteps = [
  <>
    <span className="text-2xl font-semibold text-stone-800">Welcome!</span>
    <br />
    <br />
    Personal growth is hard, and can be overwhelming. How do we make it easier?
    <br />
    <br />
    Habits is the answer. As books like Atomic Habits show, small, simple changes, repeated daily, take advantage of
    “neuroplasticity” to carve new pathways in your brain. Over time these create big changes in how you behave, which become so
    much part of you that you hardly have to think about them.
  </>,
  <>
    But which habits? Where should I focus first?
    <br />
    <br />
    Ancient philosophers identified – and modern science has confirmed – a specific set of superpower habits that make your life
    easier, more effective, happier and healthier.
  </>,
  <>
    Take our assessment to identify your areas for growth and learn how to cultivate these superhabits for overall personal
    development. <br />
    <br />
    The assessment will take about 5 to 10 minutes.
  </>,
];

const HomePage: React.FC = () => {
  const practices = useQuery(api.practices.get);

  const [currentStep, setCurrentStep] = useState(0);

  const handleNext = () => {
    if (currentStep < introSteps.length - 1) {
      setCurrentStep(currentStep + 1);
    }
  };

  const handleBack = () => {
    if (currentStep > 0) {
      setCurrentStep(currentStep - 1);
    }
  };

  return (
    <IonPage>
      <IonHeader className="shadow-none border-0">
        <IonToolbar>
          <IonButtons slot="start" className="px-5 py-3">
            <UserButton />
          </IonButtons>
          <IonButtons slot="end" className="px-5 py-3">
            <Link to="/new">
              <Button className="rounded-full" size="sm" variant="secondary">
                <PlusCircle className="w-4 h-4 mr-2" color="#191919" />
                <span className="text-xs font-semibold text-stone-800">Habit</span>
              </Button>
            </Link>
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="h-full max-w-2xl mx-auto">
          {practices && practices.length > 0 ? (
            <div className="overflow-auto">
              <div className="p-4 space-y-3 w-full h-full">
                {practices.map((practice) => (
                  <PracticeCard key={practice._id} practice={practice} />
                ))}
              </div>
              <div className="h-[100px]" />
            </div>
          ) : (
            <div className="flex justify-center h-full max-w-2xl mx-auto p-3">
              <Card className={`flex flex-col items-center bg-white rounded-3xl overflow-hidden p-8 md:p-16 pb-16 relative`}>
                <div className={`flex flex-col space-y-5 mb-8 overflow-auto`}>
                  <span className="text-base md:text-lg text-stone-700 font-semibold">{introSteps[currentStep]}</span>
                </div>
                <div className="absolute bottom-0 left-0 right-0 bg-white p-6 flex space-x-2 items-center">
                  {currentStep > 0 ? (
                    <Button className="rounded-2xl p-6" variant="secondary" onClick={handleBack}>
                      <ArrowLeft className="w-4 h-4" />
                    </Button>
                  ) : null}
                  {currentStep < introSteps.length - 1 ? (
                    <Button className="rounded-2xl p-6 w-full flex-grow" onClick={handleNext}>
                      Next
                      <ArrowRight className="w-4 h-4 ml-2" />
                    </Button>
                  ) : (
                    <Link to="/diagnostic" className="w-full">
                      <Button className="rounded-2xl p-6 w-full">
                        <BookCheck className="w-4 h-4 mr-2" />
                        Take the Assessment
                      </Button>
                    </Link>
                  )}
                </div>
              </Card>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default HomePage;
