import { Redirect, Route } from "react-router-dom";
import { IonApp, IonLabel, IonRouterOutlet, IonTabBar, IonTabButton, IonTabs, setupIonicReact } from "@ionic/react";
import { IonReactRouter } from "@ionic/react-router";
import HomePage from "./pages/HomePage";
import ProgressPage from "./pages/ProgressPage";
import ResultsPage from "./pages/ResultsPage";

/* Core CSS required for Ionic components to work properly */
import "@ionic/react/css/core.css";

/* Basic CSS for apps built with Ionic */
import "@ionic/react/css/normalize.css";
import "@ionic/react/css/structure.css";
import "@ionic/react/css/typography.css";

/* Optional CSS utils that can be commented out */
import "@ionic/react/css/padding.css";
import "@ionic/react/css/float-elements.css";
import "@ionic/react/css/text-alignment.css";
import "@ionic/react/css/text-transformation.css";
import "@ionic/react/css/flex-utils.css";
import "@ionic/react/css/display.css";

/**
 * Ionic Dark Mode
 * -----------------------------------------------------
 * For more info, please see:
 * https://ionicframework.com/docs/theming/dark-mode
 */

/* import '@ionic/react/css/palettes/dark.always.css'; */
/* import '@ionic/react/css/palettes/dark.class.css'; */
// import "@ionic/react/css/palettes/dark.system.css";

/* Theme variables */
import "./theme/variables.css";
import { BarChart2, BookCheck, CheckCircle, Dot } from "lucide-react";

import { SignIn, useUser } from "@clerk/clerk-react";
import { Authenticated, Unauthenticated } from "convex/react";
import DiagnosticPage from "./pages/DiagnosticPage";

import { usePostHog } from "posthog-js/react";
import { useEffect, useState } from "react";
import NewHabitPage from "./pages/NewHabitPage";
import HabitPage from "./pages/HabitPage";
import PracticePage from "./pages/PracticePage";

setupIonicReact();

const App: React.FC = () => {
  const posthog = usePostHog();
  const { user } = useUser();
  const [currentTab, setCurrentTab] = useState<string>("home");

  useEffect(() => {
    if (user && posthog) {
      posthog.identify(user.id, {
        unique_id: user.id,
      });
    }
  }, [posthog, user]);

  return (
    <IonApp>
      <Unauthenticated>
        <div className="flex items-center justify-around h-full">
          <SignIn />
        </div>
      </Unauthenticated>
      <Authenticated>
        <IonReactRouter>
          <IonTabs onIonTabsDidChange={(e) => setCurrentTab(e.detail.tab)}>
            <IonRouterOutlet>
              <Route exact path="/home">
                <HomePage />
              </Route>
              <Route exact path="/progress">
                <ProgressPage />
              </Route>
              <Route exact path="/diagnostic">
                <DiagnosticPage />
              </Route>
              <Route exact path="/results">
                <ResultsPage />
              </Route>
              <Route exact path="/new">
                <NewHabitPage />
              </Route>
              <Route exact path="/habits/:id">
                <HabitPage />
              </Route>
              <Route exact path="/practices/:id">
                <PracticePage />
              </Route>
              <Route exact path="/">
                <Redirect to="/home" />
              </Route>
            </IonRouterOutlet>
            <IonTabBar slot="bottom" style={{ backgroundColor: "var(--ion-background-color)" }}>
              <IonTabButton tab="home" href="/home" className="bg-transparent">
                <CheckCircle strokeWidth={2} />
                <Dot className={`-my-2 ${currentTab === "home" ? "opacity-100" : "opacity-0"}`} />
              </IonTabButton>
              <IonTabButton tab="progress" href="/progress" className="bg-transparent">
                <BarChart2 strokeWidth={2} />
                <Dot className={`-my-2 ${currentTab === "progress" ? "opacity-100" : "opacity-0"}`} />
              </IonTabButton>
              <IonTabButton tab="results" href="/results" className="bg-transparent">
                <BookCheck strokeWidth={2} />
                <Dot className={`-my-2 ${currentTab === "results" ? "opacity-100" : "opacity-0"}`} />
              </IonTabButton>
            </IonTabBar>
          </IonTabs>
        </IonReactRouter>
      </Authenticated>
    </IonApp>
  );
};

export default App;
