import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { UserButton } from "@clerk/clerk-react";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { useMemo, useState } from "react";
import { Doc, Id } from "convex/_generated/dataModel";
import NewHabitWizard from "@/components/NewHabitWizard";
import { Link } from "react-router-dom";
import { ArrowRight } from "lucide-react";

const NewHabitPage: React.FC = () => {
  const diagnosticScore = useQuery(api.diagnosticScores.getLatest);
  const habits = useQuery(api.habits.get);
  const practices = useQuery(api.practices.get);
  const scores = diagnosticScore?.scores.sort((a, b) => a.score - b.score);
  const [selectedHabit, setSelectedHabit] = useState<Doc<"habits"> | null>(null);

  const recommendedHabits = useMemo(() => {
    if (!scores || !practices) return [];

    const existingPracticeHabitIds = new Set(practices.map((practice) => practice.habitId));

    return scores
      .filter((score) => !existingPracticeHabitIds.has(score.habit?._id as Id<"habits">))
      .sort((a, b) => a.score - b.score)
      .slice(0, 3);
  }, [practices, scores]);

  const handleSelectHabit = (habitId: Id<"habits"> | undefined) => {
    const habit = habits?.find((h) => h._id === habitId);
    if (habit) {
      setSelectedHabit(habit);
    }
  };

  const handleSave = () => {
    setSelectedHabit(null);
  };

  return (
    <IonPage>
      <IonHeader className="shadow-none">
        <IonToolbar>
          <IonButtons slot="start" className="px-5 py-3">
            <UserButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {selectedHabit ? (
          <div className="p-7 h-full space-y-4 max-w-2xl mx-auto overflow-auto">
            <NewHabitWizard habit={selectedHabit} onSave={handleSave} />
          </div>
        ) : (
          <div className="p-4 h-full space-y-4 max-w-2xl mx-auto">
            <h1 className="text-xl font-bold px-4 pt-2">Which habit do you want to work on?</h1>
            {diagnosticScore ? (
              <div className="flex flex-col gap-2 pt-2">
                <h2 className="text-sm pl-4 text-stone-700 font-semibold">Recommended for you</h2>
                {recommendedHabits.length &&
                  recommendedHabits.map((score) => (
                    <Link to={`/habits/${score.habit?._id}`} key={score.habit?._id}>
                      <Card>
                        <div className="p-5">
                          <div className="flex justify-between items-center pl-1">
                            <div className="text-primary font-bold">{score.habit?.name}</div>
                            <div className="flex items-center">
                              <div
                                className={`font-bold text-md italic ${score.score >= 4 ? "text-green-500" : score.score >= 3 ? "text-yellow-500" : "text-red-500"}`}
                              >
                                {score.score.toFixed(1)}
                              </div>
                              <div className="flex justify-end pl-3">
                                <Button size="icon" variant="outline" className="rounded-full">
                                  <ArrowRight className="w-4 h-4" />
                                </Button>
                              </div>
                            </div>
                          </div>
                          {/* <div className="pt-4 text-xs font-light">{score.habit?.definition}</div> */}
                        </div>
                      </Card>
                    </Link>
                  ))}
                <h2 className="text-sm pl-4 pt-5 text-stone-700 font-semibold">All habits</h2>
                {scores?.map((score) => (
                  <Link to={`/habits/${score.habit?._id}`} key={score.habit?._id}>
                    <Card>
                      <div className="p-5">
                        <div className="flex justify-between items-center pl-1">
                          <div className="text-primary font-bold">{score.habit?.name}</div>
                          <div className="flex items-center">
                            <div
                              className={`font-bold text-md italic ${score.score >= 4 ? "text-green-500" : score.score >= 3 ? "text-yellow-500" : "text-red-500"}`}
                            >
                              {score.score.toFixed(1)}
                            </div>
                            <div className="flex justify-end pl-3">
                              <Button size="icon" variant="outline" className="rounded-full">
                                <ArrowRight className="w-4 h-4" />
                              </Button>
                            </div>
                          </div>
                        </div>
                        {/* <div className="pt-4 text-xs font-light">{score.habit?.definition}</div> */}
                      </div>
                    </Card>
                  </Link>
                ))}
                <div className="h-[100px]" />
              </div>
            ) : (
              <div className="flex flex-col gap-2 pt-2">
                {habits?.map((habit) => (
                  <Link to={`/habits/${habit._id}`} key={habit._id}>
                    <Card>
                      <div className="p-5">
                        <div className="flex justify-between items-center pl-1">
                          <div className="text-primary font-bold">{habit.name}</div>
                          <div className="flex items-center">
                            <div className="flex justify-end pl-3">
                              <Button size="icon" variant="outline" className="rounded-full">
                                <ArrowRight className="w-4 h-4" />
                              </Button>
                            </div>
                          </div>
                        </div>
                        {/* <div className="pt-4 text-xs font-light">{score.habit?.definition}</div> */}
                      </div>
                    </Card>
                  </Link>
                ))}
                <div className="h-[100px]" />
              </div>
            )}
          </div>
        )}
      </IonContent>
    </IonPage>
  );
};

export default NewHabitPage;
