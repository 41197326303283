import Diagnostic from "@/components/Diagnostic";
import { IonContent, IonPage } from "@ionic/react";

const DiagnosticPage: React.FC = () => {
  return (
    <IonPage>
      <IonContent fullscreen>
        <Diagnostic />
      </IonContent>
    </IonPage>
  );
};

export default DiagnosticPage;
