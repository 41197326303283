import React, { useEffect, useState } from "react";
import { Card, CardContent } from "./ui/card";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Button } from "./ui/button";
import { Progress } from "./ui/progress";
import { ArrowLeft, ArrowRight, Share2Icon } from "lucide-react";
import { Doc, Id } from "convex/_generated/dataModel";
import { Link, useHistory } from "react-router-dom";
import { useUser } from "@clerk/clerk-react";

import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { usePostHog } from "posthog-js/react";

const answerOptions = [
  { label: "Strongly Agree", value: 1 },
  { label: "Agree", value: 2 },
  { label: "Neutral", value: 3 },
  { label: "Disagree", value: 4 },
  { label: "Strongly Disagree", value: 5 },
];

// const cardinalQuestionOptions = [
//   { cardinalHabit: "Justice", description: "I would like to improve my interactions with others" },
//   { cardinalHabit: "Practical Wisdom", description: "I would like to make better decisions" },
//   { cardinalHabit: "Courage", description: "I would like to overcome my fears more easily" },
//   { cardinalHabit: "Self-discipline", description: "I would like to resist temptations more easily" },
// ];

const STORAGE_KEY = "shuffledDiagnosticQuestions";

const Diagnostic: React.FC = () => {
  const { user } = useUser();
  const posthog = usePostHog();
  const habits = useQuery(api.habits.get);
  const diagnosticQuestions = useQuery(api.diagnosticQuestions.get);

  const createDiagnosticScores = useMutation(api.diagnosticScores.create);

  const [currentIndex, setCurrentIndex] = useState(0);
  const [answers, setAnswers] = useState<number[]>([]);
  const [habitScores, setHabitScores] = useState<Record<string, number>>({});
  const [sortedHabits, setSortedHabits] = useState<Doc<"habits">[]>([]);
  const [isQuestionVisible, setIsQuestionVisible] = useState(true);
  const [shuffledQuestions, setShuffledQuestions] = useState<typeof diagnosticQuestions>([]);
  const [isComplete, setIsComplete] = useState(false);
  console.log(isComplete);
  const [startTime, setStartTime] = useState<number | null>(null);
  const [step, setStep] = useState(0);
  const [previousScores, setPreviousScores] = useState<Record<string, number>>({});
  console.log(previousScores);
  console.log(diagnosticQuestions);
  const history = useHistory();

  const latestDiagnosticScores = useQuery(api.diagnosticScores.getLatest);

  useEffect(() => {
    if (latestDiagnosticScores) {
      const scores = latestDiagnosticScores.scores.reduce(
        (acc, score) => {
          acc[score.habitId] = score.score;
          return acc;
        },
        {} as Record<string, number>
      );
      setPreviousScores(scores);
    }
  }, [latestDiagnosticScores]);

  useEffect(() => {
    if (!diagnosticQuestions || !habits) return;

    const filterQuestions = (questions: typeof diagnosticQuestions) => {
      // If there are no previous scores, return all questions
      if (Object.keys(previousScores).length === 0) {
        return questions;
      }

      return questions.filter((question) => {
        const habit = habits.find((h) => h.name === question.habit);
        if (!habit) return false;
        const previousScore = previousScores[habit._id];
        return previousScore === undefined || previousScore < 5;
      });
    };

    let questionsToUse = diagnosticQuestions;
    const storedQuestions = sessionStorage.getItem(STORAGE_KEY);
    if (storedQuestions) {
      questionsToUse = JSON.parse(storedQuestions);
    }

    let filteredQuestions = filterQuestions(questionsToUse);

    if (filteredQuestions.length !== questionsToUse.length) {
      // If questions were filtered out, shuffle the remaining ones
      filteredQuestions = [...filteredQuestions].sort(() => Math.random() - 0.5);
      sessionStorage.setItem(STORAGE_KEY, JSON.stringify(filteredQuestions));
    }
    console.log(filteredQuestions);
    console.log("hello");
    setShuffledQuestions(filteredQuestions);
  }, [diagnosticQuestions, previousScores, habits]);

  const calculateHabitScores = (finalAnswers: number[]) => {
    const newScores: Record<string, number> = { ...previousScores };

    shuffledQuestions?.forEach((question, index) => {
      const habit = habits?.find((h) => h.name === question.habit);
      if (habit) {
        const newScore = question.scores[finalAnswers[index] - 1];
        newScores[habit._id] = newScore;
      }
    });

    const endTime = Date.now();
    const completionTimeSeconds = startTime ? (endTime - startTime) / 1000 : null;

    posthog.capture("user_completed_diagnostic", {
      scores: newScores,
      completion_time_seconds: completionTimeSeconds,
    });

    setHabitScores(newScores);
    handleSaveDiagnosticScores(newScores);
    getSortedHabits(newScores);
    sessionStorage.removeItem("shuffledDiagnosticQuestions");

    if (latestDiagnosticScores) {
      // If this is a repeat diagnostic, navigate directly to results
      history.push("/results");
    }
  };

  const getSortedHabits = (scores: Record<string, number>) => {
    if (!habits) return;

    const sortedHabits = habits
      .map((habit) => ({
        habit,
        score: scores[habit._id] || 0,
      }))
      .sort((a, b) => b.score - a.score);

    setSortedHabits(sortedHabits.map(({ habit }) => habit));
  };

  const handleSelect = (value: number) => {
    const newAnswers = [...answers];
    newAnswers[currentIndex] = value;
    setAnswers(newAnswers);

    if (shuffledQuestions && currentIndex < shuffledQuestions.length - 1) {
      setIsQuestionVisible(false);
      setTimeout(() => {
        setCurrentIndex(currentIndex + 1);
        setIsQuestionVisible(true);
      }, 300); // This should match the transition duration in CSS
    } else {
      handleSubmit(newAnswers);
    }
  };

  const handleSubmit = (finalAnswers: number[]) => {
    calculateHabitScores(finalAnswers);
    if (!latestDiagnosticScores) {
      setIsComplete(true);
    }
  };

  const handlePrevious = () => {
    if (currentIndex > 0) {
      setCurrentIndex(currentIndex - 1);
    }
  };

  const handleSaveDiagnosticScores = async (scores: Record<string, number>) => {
    if (!habits) return;

    const scoresToSave = Object.entries(scores).map(([habitId, score]) => ({
      habitId,
      score,
    }));

    try {
      await createDiagnosticScores({
        scores: scoresToSave.map(({ habitId, score }) => ({
          habitId: habitId as Id<"habits">,
          score: score as number,
        })),
      });
      console.log("Diagnostic scores saved successfully");
    } catch (error) {
      console.error("Failed to save diagnostic scores:", error);
    }
  };

  function generateReferralLink(baseUrl: string): string {
    const utmParams = new URLSearchParams({
      utm_source: "app",
      utm_medium: "referral",
      utm_campaign: "user_referral",
    });

    return `${baseUrl}?${utmParams.toString()}`;
  }

  const handleShareResults = async () => {
    posthog.capture("user_shared_diagnostic_results");
    try {
      await document.fonts.load("bold 16px 'Noto Sans Mono'");
      await document.fonts.load("16px 'Noto Sans Mono'");
      const top5Habits = sortedHabits.slice(0, 5);
      const canvas = document.createElement("canvas");
      canvas.width = 500; // Increased width for more edge space
      canvas.height = 500; // Increased height for more space
      const ctx = canvas.getContext("2d");
      if (!ctx) throw new Error("Could not get canvas context");

      // Set background
      ctx.fillStyle = "#f6f8fa";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Load background image
      // const backgroundImg = new Image();
      // backgroundImg.src = "../../../public/background-share-image.png";

      // await new Promise((resolve, reject) => {
      //   backgroundImg.onload = resolve;
      //   backgroundImg.onerror = reject;
      // });

      // // Draw background image
      // ctx.drawImage(backgroundImg, 0, 0, canvas.width, canvas.height);

      // Set font
      ctx.font = 'bold 20px "Noto Sans Mono"';
      ctx.fillStyle = "#333";
      // Draw title
      const title = `${user?.firstName ? `${user.firstName}'s` : "My"} Top 5 Superhabits`;
      ctx.fillText(title, 50, 70); // Moved right and down for more edge space
      // Draw habits
      top5Habits.forEach((habit, index) => {
        ctx.font = 'bold 28px "Noto Sans Mono"';
        const y = 140 + index * 60; // Increased initial Y and spacing between habits

        // Draw habit name
        ctx.fillStyle = "#333";
        ctx.fillText(`${index + 1}. ${habit.name}`, 50, y); // Moved right for more edge space
        // Draw score circle
        const score = habitScores[habit.name].toFixed(1);

        ctx.fillStyle = getScoreColor(habitScores[habit.name]);
        ctx.beginPath();
        ctx.arc(420, y - 6, 23, 0, 2 * Math.PI); // Moved right for more edge space
        ctx.fill();
        // Draw score text
        ctx.fillStyle = "white";
        ctx.font = 'bold 16px "Noto Sans Mono"'; // Smaller font for score
        const scoreWidth = ctx.measureText(score).width;
        ctx.fillText(score, 420 - scoreWidth / 2, y - 1); // Adjusted X position
      });
      // Draw footer
      ctx.font = '18px "Noto Sans Mono"';
      ctx.fillStyle = "#666";
      ctx.fillText("praxology.com", 50, 460); // Moved right and down
      // Convert canvas to blob
      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((result) => {
          if (result) resolve(result);
          else reject(new Error("Failed to create blob"));
        }, "image/png");
      });

      if (Capacitor.isNativePlatform()) {
        // Use Capacitor Share plugin for native apps
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64data = reader.result as string;
          await Share.share({
            title: "My Top 5 Superhabits",
            text: `Check out my top 5 Superhabits! You can find yours at ${generateReferralLink("https://praxology.com")}`,
            url: base64data,
            dialogTitle: "Share your top 5 habits",
          });
        };
      } else if ("share" in navigator) {
        // Use Web Share API for supported browsers
        const file = new File([blob], "my-top-5-habits.png", { type: "image/png" });
        await navigator.share({
          title: "My Top 5 Superhabits",
          text: `Check out my top 5 Superhabits! You can find yours at ${generateReferralLink("https://praxology.com")}`,
          files: [file],
        });
      } else {
        // Fallback for desktop browsers without share support
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "my-top-5-superhabits.png";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
      console.log("downloaded");
    } catch (error) {
      console.error("Error sharing results:", error);
    }
  };

  // Helper function to get color based on score
  const getScoreColor = (score: number) => {
    if (score >= 4) return "#22c55e"; // green-500
    if (score >= 3) return "#eab308"; // yellow-500
    return "#ef4444"; // red-500
  };

  if (!shuffledQuestions || shuffledQuestions.length === 0) return null;

  const currentQuestion = shuffledQuestions[currentIndex];

  const progress = ((currentIndex + 1) / shuffledQuestions.length) * 100;

  if (isComplete) {
    if (step === 0) {
      return (
        <div className="max-w-2xl mx-auto p-6">
          <div className="p-2">
            <h2 className="text-2xl font-bold mb-4">Results</h2>
            <p className="text-stone-800 mb-4 text-sm">Let's start by taking a look at your top 5 superhabits</p>
          </div>
          {Capacitor.isNativePlatform() || "share" in navigator ? (
            <Button onClick={handleShareResults} className="rounded-full px-6 mb-4">
              Share Your Top 5
              <Share2Icon className="ml-2 w-4 h-4" />
            </Button>
          ) : (
            // <Button onClick={handleShareResults} className="rounded-full px-6 mb-4">
            //   Download Your Top 5
            //   <DownloadIcon className="ml-2 w-4 h-4" />
            // </Button>
            <></>
          )}

          <div className="flex flex-col space-y-3">
            {sortedHabits.slice(0, 5).map((habit) => (
              <Card key={habit._id}>
                <div className="p-5">
                  <div className="flex justify-between">
                    <div className="text-primary font-bold">{habit.name}</div>
                    <div
                      className={`font-bold text-xs ${habitScores[habit._id] >= 4 ? "text-green-500" : habitScores[habit._id] >= 3 ? "text-yellow-500" : "text-red-500"}`}
                    >
                      {(habitScores[habit._id] ?? 0).toFixed(1)}
                    </div>
                  </div>
                  <div className="pt-4 text-xs font-light">{habit.definition}</div>
                </div>
              </Card>
            ))}
          </div>
          <div className="h-[100px]" />
          <div className="fixed bottom-0 left-0 right-0">
            <div className="flex flex-col w-full max-w-md mx-auto p-4">
              <Button onClick={() => setStep(1)} className="w-full rounded-full">
                Next
                <ArrowRight size={16} className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (step === 1) {
      return (
        <div className="max-w-2xl mx-auto p-6">
          <div className="p-2">
            <h2 className="text-xl font-bold mb-4">So should I just play to my strengths?</h2>
            <p className="text-stone-800 mb-4 text-base">
              You could – but real personal growth comes from working on your weakest link: your lowest-scoring superhabit.
            </p>
          </div>
          <div className="h-[100px]" />
          <div className="fixed bottom-0 left-0 right-0">
            <div className="flex w-full max-w-md mx-auto p-4 space-x-2">
              <Button onClick={() => setStep(0)} size="icon" variant="secondary" className="rounded-full w-14">
                <ArrowLeft size={16} />
              </Button>
              <Button onClick={() => setStep(2)} className="w-full rounded-full">
                Next
                <ArrowRight size={16} className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (step === 2) {
      return (
        <div className="max-w-2xl mx-auto p-6">
          <div className="p-2">
            <h2 className="text-xl font-bold mb-4">Why should I focus on my weakest link?</h2>
            <p className="text-stone-800 mb-5 text-sm">
              Each of the superhabits rolls up to one of four – pivotal – superhabits. Each manages a core part of your life: your
              thoughts, actions, and feelings (fears and desires).
            </p>
            <ul>
              <li>
                <div className="p-4 px-5 -mx-2 mb-3 border-2 border-stone-200 rounded-3xl">
                  <p className="text-stone-800 text-sm">
                    <b>Practical Wisdom</b> is the superhabit for managing your thoughts, and particularly for making wise decisions
                  </p>
                </div>
              </li>
              <li>
                <div className="p-4 px-5 -mx-2 mb-3 border-2 border-stone-200 rounded-3xl">
                  <p className="text-stone-800 text-sm">
                    <b>Justice</b> is the superhabit for managing your actions, and particularly your interactions with others{" "}
                  </p>
                </div>
              </li>
              <li>
                <div className="p-4 px-5 -mx-2 mb-3 border-2 border-stone-200 rounded-3xl">
                  <p className="text-stone-800 text-sm">
                    <b>Courage</b> is the superhabit for managing feelings that repel: i.e. your fears{" "}
                  </p>
                </div>
              </li>
              <li>
                <div className="p-4 px-5 -mx-2 mb-3 border-2 border-stone-200 rounded-3xl">
                  <p className="text-stone-800 text-sm">
                    <b>Self-discipline</b> is the superhabit for managing feelings that attract: i.e. your desires{" "}
                  </p>
                </div>
              </li>
            </ul>
            <p className="text-stone-800 pt-3 text-sm">
              Your weakest superhabit inhibits, even undermines, your ability to exercise the pivotal superhabit that it belongs to.
            </p>
          </div>
          <div className="h-[100px]" />
          <div className="fixed bottom-0 left-0 right-0">
            <div className="flex w-full max-w-md mx-auto p-4 space-x-2">
              <Button onClick={() => setStep(1)} size="icon" variant="secondary" className="rounded-full w-14">
                <ArrowLeft size={16} />
              </Button>
              <Button onClick={() => setStep(3)} className="w-full rounded-full">
                Next
                <ArrowRight size={16} className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (step === 3) {
      return (
        <div className="max-w-2xl mx-auto p-6">
          <div className="p-2">
            <h2 className="text-lg font-bold mb-6">Here is your current weakest link:</h2>
            <div className="p-5 px-6 -mx-2 mb-6 border-2 border-stone-200 rounded-3xl">
              <div className="flex justify-between">
                <div className="text-primary font-bold text-xl">{sortedHabits[sortedHabits.length - 1].name}</div>
              </div>
              <div className="pt-4 text-sm">{sortedHabits[sortedHabits.length - 1].definition}</div>
            </div>
            <p className="text-stone-800 mb-4 text-sm">
              Your weakest link, {sortedHabits[sortedHabits.length - 1].name}, is inhibiting or even undermining your ability to
              exercise the pivotal superhabit of {sortedHabits[sortedHabits.length - 1].parent_habit}.
            </p>
          </div>
          <div className="h-[100px]" />
          <div className="fixed bottom-0 left-0 right-0">
            <div className="flex w-full max-w-md mx-auto p-4 space-x-2">
              <Button onClick={() => setStep(2)} size="icon" variant="secondary" className="rounded-full w-14">
                <ArrowLeft size={16} />
              </Button>
              <Button onClick={() => setStep(4)} className="w-full rounded-full">
                Next
                <ArrowRight size={16} className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (step === 4) {
      return (
        <div className="max-w-2xl mx-auto p-6">
          <div className="p-2">
            <h2 className="text-lg font-bold mb-4">
              So why don't I just work on {sortedHabits[sortedHabits.length - 1].parent_habit} directly?
            </h2>
            <p className="text-stone-800 mb-4 text-base">
              Because it's hard! {sortedHabits[sortedHabits.length - 1].parent_habit} is complicated.
              <br />
              <br />
              It's easier to grow in one of the superhabits that make up {sortedHabits[sortedHabits.length - 1].parent_habit}.
            </p>
          </div>
          <div className="h-[100px]" />
          <div className="fixed bottom-0 left-0 right-0">
            <div className="flex w-full max-w-md mx-auto p-4 space-x-2">
              <Button onClick={() => setStep(3)} size="icon" variant="secondary" className="rounded-full w-14">
                <ArrowLeft size={16} />
              </Button>
              <Button onClick={() => setStep(5)} className="w-full rounded-full">
                Next
                <ArrowRight size={16} className="ml-2" />
              </Button>
            </div>
          </div>
        </div>
      );
    }

    if (step === 5) {
      return (
        <div className="max-w-2xl mx-auto p-6">
          <div className="p-2">
            <h2 className="text-lg font-bold mb-4">
              But if {sortedHabits[sortedHabits.length - 1].name} is my biggest weakness, how can I change that?
            </h2>
            <p className="text-stone-800 mb-4 text-base">
              The trick is to pick something really, really simple, that you can do every day, and reward yourself each time you do
              it, if that helps.
              <br />
              <br />
              Sometimes you'll fail, and that's ok, but keep trying. After a few days, or weeks (the length of time will vary by
              superhabit and by person) it will become natural to you, and it will no longer be hard, and you can move on to the
              next thing.
              <br />
              <br />
              Are you ready to start growing in {sortedHabits[sortedHabits.length - 1].name}?
            </p>
          </div>
          <div className="h-[100px]" />
          <div className="fixed bottom-6 right-6 left-6">
            <div className="flex space-x-2 justify-between w-full max-w-md mx-auto">
              <Button onClick={() => setStep(4)} size="icon" variant="secondary" className="rounded-full w-14">
                <ArrowLeft size={16} />
              </Button>
              <Link to={`/habits/${sortedHabits[sortedHabits.length - 1]._id}`} className="w-full">
                <Button className="w-full rounded-full px-6">
                  I'm ready
                  <ArrowRight size={16} className="ml-2" />
                </Button>
              </Link>
            </div>
          </div>
        </div>
      );
    }

    return null;
  }

  if (!currentQuestion) return null;

  return (
    <div className="flex flex-col h-full max-w-2xl mx-auto p-4">
      <div className="flex-grow overflow-y-auto pb-20">
        <div className="p-1 pt-3 pb-5">
          <div
            className={`text-base font-semibold text-stone-800 mb-3 p-3 transition-opacity duration-300 ${
              isQuestionVisible ? "opacity-100" : "opacity-0"
            }`}
          >
            {currentQuestion.question}
          </div>
          <div
            className={`flex flex-col space-y-2 transition-opacity duration-300 ${isQuestionVisible ? "opacity-100" : "opacity-0"}`}
          >
            {[1, 2, 3, 4, 5].map((value) => (
              <Card
                key={value}
                className={`cursor-pointer transition-all p-2 rounded-3xl border-2 border-stone-200 flex-1 ${
                  answers[currentIndex] === value ? "bg-secondary border-primary" : ""
                }`}
                style={{ boxShadow: "none" }}
                onClick={() => handleSelect(value)}
              >
                <CardContent className="flex flex-col items-center justify-center p-2 h-full text-sm">
                  {answerOptions.find((option) => option.value === value)?.label}
                </CardContent>
              </Card>
            ))}
          </div>
        </div>
        {currentIndex !== 0 && (
          <div className="fixed bottom-0 left-0 right-0 bg-white">
            <div className="flex flex-col w-full max-w-md mx-auto p-4">
              <Progress value={progress} className="mb-2 h-2" />
              <div className="flex justify-between mt-2">
                <Button onClick={handlePrevious}>
                  <ArrowLeft className="w-4 h-4 mr-2" />
                  Back
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
    </div>
  );
};

export default Diagnostic;
