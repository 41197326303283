import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { UserButton } from "@clerk/clerk-react";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import ProgressCard from "@/components/ProgressCard";

const ProgressPage: React.FC = () => {
  const practices = useQuery(api.practices.get);

  return (
    <IonPage>
      <IonHeader className="shadow-none">
        <IonToolbar>
          <IonButtons slot="start" className="px-5 py-4">
            <UserButton />
          </IonButtons>
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="p-4 space-y-4 max-w-2xl mx-auto">
          {practices && practices.length > 0 ? (
            practices.map((practice) => <ProgressCard key={practice._id} practice={practice} />)
          ) : (
            <div className="flex items-center justify-center h-full">
              <div className="text-sm">No Data</div>
            </div>
          )}
        </div>
        {/* <div className="h-[1200px]" /> */}
      </IonContent>
    </IonPage>
  );
};

export default ProgressPage;
