import React from "react";
import { createRoot } from "react-dom/client";
import App from "./App";
import { ConvexReactClient } from "convex/react";
import { ClerkProvider, useAuth } from "@clerk/clerk-react";
import { ConvexProviderWithClerk } from "convex/react-clerk";

import { PostHogProvider } from "posthog-js/react";
import * as Sentry from "@sentry/react";

Sentry.init({
  dsn: import.meta.env.VITE_SENTRY_DSN,
  integrations: [Sentry.browserTracingIntegration(), Sentry.browserProfilingIntegration()],
  // Performance Monitoring
  tracesSampleRate: 1.0, //  Capture 100% of the transactions
  // Set 'tracePropagationTargets' to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", /^https:\/\/yourserver\.io\/api/],
  // Profiling
  profilesSampleRate: 1.0, // Profile 100% of the transactions. This value is relative to tracesSampleRate
});

const convex = new ConvexReactClient(import.meta.env.VITE_CONVEX_URL as string);

const options = {
  api_host: import.meta.env.VITE_PUBLIC_POSTHOG_HOST,
};

const container = document.getElementById("root");
const root = createRoot(container!);
const AppWithProviders = () => (
  <React.StrictMode>
    <ClerkProvider publishableKey={import.meta.env.VITE_CLERK_PUBLISHABLE_KEY}>
      <ConvexProviderWithClerk client={convex} useAuth={useAuth}>
        <App />
      </ConvexProviderWithClerk>
    </ClerkProvider>
  </React.StrictMode>
);

if (import.meta.env.MODE === "production") {
  root.render(
    <PostHogProvider apiKey={import.meta.env.VITE_PUBLIC_POSTHOG_KEY} options={options}>
      <AppWithProviders />
    </PostHogProvider>
  );
} else {
  root.render(<AppWithProviders />);
}
