import React, { useState, useEffect } from "react";
import { Accordion, AccordionItem, AccordionTrigger, AccordionContent } from "@/components/ui/accordion";
import { Button } from "@/components/ui/button";
import { Slider } from "@/components/ui/slider";
import { Check, CheckCircle2, Settings2, Undo2Icon } from "lucide-react";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Practice } from "@/types";
import { toZonedTime, format } from "date-fns-tz";
import { Label } from "./ui/label";
import { Card } from "./ui/card";
import { Link } from "react-router-dom";
import { usePostHog } from "posthog-js/react";

interface PracticeCardProps {
  practice: Practice;
}

const PracticeCard: React.FC<PracticeCardProps> = ({ practice }) => {
  const posthog = usePostHog();
  const completions = useQuery(api.completions.get, { practiceId: practice._id });
  const completePractice = useMutation(api.completions.create);
  const uncompletePractice = useMutation(api.completions.deleteCurrentCompletion);

  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const [expandedHabitId, setExpandedHabitId] = useState<string | null>(null);
  const [effortLevel, setEffortLevel] = useState(5);
  const [isCompleted, setIsCompleted] = useState(false);

  useEffect(() => {
    if (completions) {
      const todayLocal = new Date().setHours(0, 0, 0, 0);
      setIsCompleted(
        completions.some((completion) => {
          const localCompletionDate = toZonedTime(completion.date, userTimeZone);
          return localCompletionDate.getTime() >= todayLocal;
        })
      );
    }
  }, [completions]);

  const last7Days = Array.from({ length: 7 }, (_, i) => {
    const d = new Date();
    d.setDate(d.getDate() - i);
    return {
      date: format(d, "yyyy-MM-dd", { timeZone: userTimeZone }),
      day: format(d, "EEE", { timeZone: userTimeZone }),
      dayOfMonth: format(d, "d", { timeZone: userTimeZone }),
    };
  }).reverse();

  const handleCompletePractice = async () => {
    posthog.capture("user_completed_practice", {
      effort: effortLevel,
      habit: practice.habitName,
      title: practice.title,
    });

    try {
      await completePractice({
        habitId: practice.habitId,
        practiceId: practice._id,
        effort: effortLevel,
      });

      setIsCompleted(true);
      setExpandedHabitId(null); // Close the accordion
    } catch (error) {
      console.error("Failed to complete practice:", error);
    }
  };

  const handleUncompletePractice = async () => {
    try {
      await uncompletePractice({
        practiceId: practice._id,
        date: Date.now(),
      });

      setIsCompleted(false);
      setExpandedHabitId(null); // Close the accordion
    } catch (error) {
      console.error("Failed to uncomplete practice:", error);
    }
  };

  return (
    <Accordion type="single" collapsible value={expandedHabitId || ""} onValueChange={setExpandedHabitId}>
      <AccordionItem value={practice._id.toString()} className="border-0">
        <Card className="p-7">
          <AccordionTrigger className="w-full flex flex-col space-y-3">
            <div className="flex space-x-4 w-full">
              <div className="flex flex-col space-y-2 items-start">
                <Label className="text-primary font-extrabold text-base">{practice.habitName}</Label>
                <h1 className="text-xs font-bold text-stone-600 mr-2 text-left">{practice.title}</h1>
              </div>
            </div>
            <div className="flex items-center justify-between w-full">
              <div className="flex space-x-1">
                {last7Days.map((day, index) => {
                  const dayCompletion = completions?.find((completion) => {
                    const localCompletionDate = toZonedTime(completion.date, userTimeZone);
                    return format(localCompletionDate, "yyyy-MM-dd", { timeZone: userTimeZone }) === day.date;
                  });
                  return (
                    <div key={index} className="flex flex-col items-center">
                      <span
                        className={`w-5 h-5 flex items-center justify-center rounded-lg text-[10px] mb-1 font-bold ${
                          dayCompletion
                            ? "bg-primary text-white border-2 border-primary"
                            : index === 6
                              ? "bg-white text-primary border-2 border-primary"
                              : "bg-secondary/40 text-stone-400"
                        }`}
                      >
                        {day.day[0]}
                      </span>
                      <span className="text-[10px] text-stone-400 font-bold">{day.dayOfMonth}</span>
                    </div>
                  );
                })}
              </div>
              <div>
                {isCompleted ? (
                  <div className={"w-10 h-10 rounded-full flex items-center justify-center bg-primary shadow-lg"}>
                    <span className="text-white">
                      <Check className="w-8" />
                    </span>
                  </div>
                ) : (
                  <div className={"w-10 h-10 rounded-full flex items-center justify-center border border-primary"}></div>
                )}
              </div>
            </div>
          </AccordionTrigger>
          <AccordionContent className="mt-4 pb-0">
            {!isCompleted && (
              <>
                <h3 className="text-sm font-semibold mb-4 pt-2 text-stone-800">How easy was it to complete this practice?</h3>
                <Slider
                  value={[effortLevel]}
                  onValueChange={(value) => setEffortLevel(value[0])}
                  max={10}
                  step={1}
                  className="mb-2"
                />
                <div className="flex justify-between text-xs text-stone-600 mb-4">
                  <span>Very Difficult</span>
                  <span>Very Easy</span>
                </div>
              </>
            )}
            <div className="flex flex-col pt-2 sm:flex-row justify-between sm:space-x-2 space-y-2 sm:space-y-0">
              <Link to={`/practices/${practice._id}`}>
                <Button variant="secondary" className="rounded-xl w-full" onClick={(event) => event.stopPropagation()}>
                  <Settings2 className="w-4 h-4 mr-2" />
                  Settings
                </Button>
              </Link>
              {!isCompleted ? (
                <Button className="rounded-xl" onClick={handleCompletePractice}>
                  <CheckCircle2 className="w-4 h-4 mr-2" />
                  Mark Complete
                </Button>
              ) : (
                <Button className="rounded-xl" variant="destructive" onClick={handleUncompletePractice}>
                  <Undo2Icon className="w-4 h-4 mr-2" />
                  Uncomplete
                </Button>
              )}
            </div>
          </AccordionContent>
        </Card>
      </AccordionItem>
    </Accordion>
  );
};

export default PracticeCard;
