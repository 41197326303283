import { IonButtons, IonContent, IonHeader, IonPage, IonToolbar } from "@ionic/react";
import { UserButton, useUser } from "@clerk/clerk-react";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Card } from "@/components/ui/card";
import { Button } from "@/components/ui/button";
import { Link } from "react-router-dom";
import { ArrowRight, BookCheck, Share2Icon, TrendingDown, TrendingUp } from "lucide-react";

import { Capacitor } from "@capacitor/core";
import { Share } from "@capacitor/share";
import { usePostHog } from "posthog-js/react";
import { Id } from "convex/_generated/dataModel";
import { Badge } from "@/components/ui/badge";

const ResultsPage: React.FC = () => {
  const posthog = usePostHog();
  const diagnosticScores = useQuery(api.diagnosticScores.getLatestTwo);
  const currentScores = diagnosticScores?.current?.scores?.sort((a, b) => b.score - a.score) ?? [];
  const previousScores = diagnosticScores?.previous?.scores;
  const { user } = useUser();

  function generateReferralLink(baseUrl: string): string {
    const utmParams = new URLSearchParams({
      utm_source: "app",
      utm_medium: "referral",
      utm_campaign: "user_referral",
    });

    return `${baseUrl}?${utmParams.toString()}`;
  }

  const getScoreChange = (habitId: Id<"habits">) => {
    if (!previousScores) return null;
    const currentScore = currentScores?.find((s) => s.habitId === habitId)?.score;
    const previousScore = previousScores.find((s) => s.habitId === habitId)?.score;
    if (currentScore === undefined || previousScore === undefined) return null;
    return currentScore - previousScore;
  };

  const handleShareResults = async () => {
    posthog.capture("user_shared_results");
    try {
      await document.fonts.load("bold 16px 'Noto Sans Mono'");
      await document.fonts.load("16px 'Noto Sans Mono'");
      const top5Habits = currentScores?.sort((a, b) => b.score - a.score).slice(0, 5);

      if (!top5Habits || top5Habits.length === 0) {
        console.error("No habits to share");
        return;
      }
      const canvas = document.createElement("canvas");
      canvas.width = 500; // Increased width for more edge space
      canvas.height = 500; // Increased height for more space
      const ctx = canvas.getContext("2d");
      if (!ctx) throw new Error("Could not get canvas context");

      // Set background
      ctx.fillStyle = "#f6f8fa";
      ctx.fillRect(0, 0, canvas.width, canvas.height);

      // Load background image
      // const backgroundImg = new Image();
      // backgroundImg.src = "../../../public/background-share-image.png";

      // await new Promise((resolve, reject) => {
      //   backgroundImg.onload = resolve;
      //   backgroundImg.onerror = reject;
      // });

      // // Draw background image
      // ctx.drawImage(backgroundImg, 0, 0, canvas.width, canvas.height);

      // Set font
      ctx.font = 'bold 20px "Noto Sans Mono"';
      ctx.fillStyle = "#333";
      // Draw title
      const title = `${user?.firstName ? `${user.firstName}'s` : "My"} Top 5 Superhabits`;
      ctx.fillText(title, 50, 70); // Moved right and down for more edge space
      // Draw habits
      top5Habits?.forEach((habit, index) => {
        ctx.font = 'bold 28px "Noto Sans Mono"';
        const y = 140 + index * 60; // Increased initial Y and spacing between habits

        // Draw habit name
        ctx.fillStyle = "#333";
        ctx.fillText(`${index + 1}. ${habit.habit?.name}`, 50, y); // Moved right for more edge space
        // Draw score circle
        const score = habit.score.toFixed(1);

        ctx.fillStyle = getScoreColor(habit.score);
        ctx.beginPath();
        ctx.arc(420, y - 6, 23, 0, 2 * Math.PI); // Moved right for more edge space
        ctx.fill();
        // Draw score text
        ctx.fillStyle = "white";
        ctx.font = 'bold 16px "Noto Sans Mono"'; // Smaller font for score
        const scoreWidth = ctx.measureText(score).width;
        ctx.fillText(score, 420 - scoreWidth / 2, y - 1); // Adjusted X position
      });
      // Draw footer
      ctx.font = '18px "Noto Sans Mono"';
      ctx.fillStyle = "#666";
      ctx.fillText("praxology.com", 50, 460); // Moved right and down
      // Convert canvas to blob
      const blob = await new Promise<Blob>((resolve, reject) => {
        canvas.toBlob((result) => {
          if (result) resolve(result);
          else reject(new Error("Failed to create blob"));
        }, "image/png");
      });

      if (Capacitor.isNativePlatform()) {
        // Use Capacitor Share plugin for native apps
        const reader = new FileReader();
        reader.readAsDataURL(blob);
        reader.onloadend = async () => {
          const base64data = reader.result as string;
          await Share.share({
            title: "My Top 5 Superhabits",
            text: `Check out my top 5 Superhabits! You can find yours at ${generateReferralLink("https://praxology.com")}`,
            url: base64data,
            dialogTitle: "Share your top 5 habits",
          });
        };
      } else if ("share" in navigator) {
        // Use Web Share API for supported browsers
        const file = new File([blob], "my-top-5-habits.png", { type: "image/png" });
        await navigator.share({
          title: "My Top 5 Superhabits",
          text: `Check out my top 5 Superhabits! You can find yours at ${generateReferralLink("https://praxology.com")}`,
          files: [file],
        });
      } else {
        // Fallback for desktop browsers without share support
        const url = URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.href = url;
        a.download = "my-top-5-superhabits.png";
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
        URL.revokeObjectURL(url);
      }
      console.log("downloaded");
    } catch (error) {
      console.error("Error sharing results:", error);
    }
  };

  // Helper function to get color based on score
  const getScoreColor = (score: number) => {
    if (score >= 4) return "#22c55e"; // green-500
    if (score >= 3) return "#eab308"; // yellow-500
    return "#ef4444"; // red-500
  };

  return (
    <IonPage>
      <IonHeader className="shadow-none">
        <IonToolbar>
          <IonButtons slot="start" className="px-5 py-3">
            <UserButton />
          </IonButtons>
          {!!currentScores.length && (
            <IonButtons slot="end" className="px-5 py-3">
              <Link to="/diagnostic">
                <Button size="sm" className="rounded-full px-4 text-xs text-stone-800" variant="secondary">
                  <BookCheck className="w-4 h-4 mr-2" color="#191919" />
                  Retake Assessment
                </Button>
              </Link>
            </IonButtons>
          )}
        </IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        <div className="p-4 h-full space-y-4 max-w-2xl mx-auto">
          {!!currentScores.length && (Capacitor.isNativePlatform() || "share" in navigator) && (
            <div className="flex justify-end">
              <Button onClick={handleShareResults} className="rounded-full px-5" size="sm">
                Share
                <Share2Icon className="ml-2 w-3 h-3" />
              </Button>
            </div>
          )}
          {!!currentScores.length ? (
            <div className="flex flex-col gap-2">
              {currentScores.map((score) => (
                <Link to={`/habits/${score.habit?._id}`} key={score.habit?._id}>
                  <Card>
                    <div className="p-5">
                      <div className="flex justify-between items-center pl-1">
                        <div className="text-primary font-bold">{score.habit?.name}</div>
                        <div className="flex items-center">
                          {previousScores &&
                            (() => {
                              const change = getScoreChange(score.habitId);
                              if (change === null || change === 0) return null;
                              const badgeColor = change > 0 ? "bg-green-200" : "bg-red-200";
                              const textColor = change > 0 ? "text-green-700" : "text-red-700";
                              const ChangeIcon = change > 0 ? TrendingUp : TrendingDown;
                              return (
                                <Badge variant="secondary" className={`mr-3 text-xs font-bold ${textColor} ${badgeColor}`}>
                                  <ChangeIcon className="w-3 h-3 mr-1" />
                                  {Math.abs(change).toFixed(1)}
                                </Badge>
                              );
                            })()}
                          <div
                            className={`font-bold text-md italic ${
                              score.score >= 4 ? "text-green-500" : score.score >= 3 ? "text-yellow-500" : "text-red-500"
                            }`}
                          >
                            {score.score.toFixed(1)}
                          </div>
                          <div className="flex justify-end">
                            <Button size="icon" variant="outline" className="rounded-full">
                              <ArrowRight className="w-4 h-4" />
                            </Button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Card>
                </Link>
              ))}
              <div className="h-[100px]" />
            </div>
          ) : (
            <div className="flex items-center justify-center h-full">
              <Link to="/diagnostic">
                <Button className="rounded-full px-5">
                  <BookCheck className="w-4 h-4 mr-2" />
                  Take the Assessment
                </Button>
              </Link>
            </div>
          )}
        </div>
      </IonContent>
    </IonPage>
  );
};

export default ResultsPage;
