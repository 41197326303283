import React, { useState } from "react";
import { Textarea } from "@/components/ui/textarea";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";
import { Doc } from "convex/_generated/dataModel";
import { Badge } from "./ui/badge";
import { useMutation } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Card } from "./ui/card";
import { Button } from "./ui/button";
import { ArrowLeft, ArrowRight, Plus } from "lucide-react";
import { Link } from "react-router-dom";

const formatHour = (hour: number): string => {
  const period = hour < 12 ? "AM" : "PM";
  const displayHour = hour % 12 || 12;
  return `${displayHour}:00 ${period}`;
};

const NewHabitWizard: React.FC<{
  habit: Doc<"habits">;
  onSave: () => void;
}> = ({ habit, onSave }) => {
  const createPractice = useMutation(api.practices.create);
  const [reminderHour, setReminderHour] = useState("");
  const [checkInHour, setCheckInHour] = useState("");
  const [dailyPractice, setDailyPractice] = useState("");
  const [step, setStep] = useState(0);

  const handleSavePractice = async () => {
    try {
      const localReminderHour = parseInt(reminderHour);
      const localCheckInHour = parseInt(checkInHour);

      const reminderDate = new Date();
      reminderDate.setHours(localReminderHour, 0, 0, 0);
      const utcReminderHour = reminderDate.getUTCHours();

      const checkInDate = new Date();
      checkInDate.setHours(localCheckInHour, 0, 0, 0);
      const utcCheckInHour = checkInDate.getUTCHours();

      // await createPractice({
      //   habitId: habit._id,
      //   title: dailyPractice,
      //   reminderHour: utcReminderHour,
      //   checkInHour: utcCheckInHour,
      // });
      setStep(0);
      onSave();
    } catch (error) {
      console.error("Failed to save practice:", error);
      // Handle error (e.g., show error message to user)
    }
  };

  if (step === 0) {
    return (
      <div className="space-y-8">
        <div>
          <h2 className="text-2xl font-bold">{habit.name}</h2>
          <p className="text-muted-foreground pt-3 text-sm">
            Let's choose a daily practice that will help you work on {habit.name}
          </p>
        </div>

        <div className="space-y-2">
          <Label className="pl-2" htmlFor="daily-practice">
            Daily Practice
          </Label>
          <Textarea
            id="daily-practice"
            placeholder="Enter your daily practice"
            className="min-h-[80px] bg-white rounded-xl text-xs"
            value={dailyPractice}
            onChange={(e) => setDailyPractice(e.target.value)}
          />
        </div>

        <div className="flex flex-col space-y-2">
          <Label className="pl-2 pb-1" htmlFor="recommendations">
            Recommendation{habit.practices.length > 1 ? "s" : ""}
          </Label>
          {habit.practices.map((practice) => (
            <Card className="rounded-xl py-3 px-4 text-xs" key={practice.id}>
              {practice}
              <div className="flex justify-end">
                <Button
                  size="icon"
                  variant="ghost"
                  className="rounded-full w-8 h-8 bg-stone-100"
                  onClick={() => setDailyPractice(practice)}
                >
                  <Plus size={16} />
                </Button>
              </div>
            </Card>
          ))}
        </div>

        <div className="fixed bottom-6 left-6">
          <Button onClick={onSave} className="rounded-full px-6 shadow-md" variant="secondary">
            <ArrowLeft size={16} className="mr-2" />
            Back
          </Button>
        </div>
        {dailyPractice !== "" && (
          <div className="fixed bottom-6 right-6">
            <Button onClick={() => setStep(1)} className="rounded-full px-6 shadow-md" disabled={dailyPractice === ""}>
              Next
              <ArrowRight size={16} className="ml-2" />
            </Button>
          </div>
        )}
      </div>
    );
  }

  if (step === 1) {
    return (
      <div className="space-y-8">
        <div>
          <h2 className="text-2xl font-bold">{habit.name}</h2>
          <p className="text-muted-foreground pt-3 text-sm">
            Let's choose a reminder schedule that will help you stay consistent in your practice of {habit.name}
          </p>
        </div>

        <div className="space-y-2">
          <Label>What time do you want to be reminded to complete your daily practice?</Label>
          <Select onValueChange={setReminderHour} value={reminderHour}>
            <SelectTrigger className="bg-white">
              <SelectValue placeholder="Select Reminder Time" />
            </SelectTrigger>
            <SelectContent>
              {Array.from({ length: 24 }, (_, i) => (
                <SelectItem key={i} value={i.toString()}>
                  {formatHour(i)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="space-y-2">
          <Label>What time should we check in to see if you've completed your daily practice?</Label>
          <Select onValueChange={setCheckInHour} value={checkInHour}>
            <SelectTrigger className="bg-white">
              <SelectValue placeholder="Select Check In Time" />
            </SelectTrigger>
            <SelectContent>
              {Array.from({ length: 24 }, (_, i) => (
                <SelectItem key={i} value={i.toString()}>
                  {formatHour(i)}
                </SelectItem>
              ))}
            </SelectContent>
          </Select>
        </div>

        <div className="fixed bottom-6 left-6">
          <Button onClick={() => setStep(0)} className="rounded-full px-6 shadow-md" variant="secondary">
            <ArrowLeft size={16} className="mr-2" />
            Back
          </Button>
        </div>
        {reminderHour !== "" && checkInHour !== "" && (
          <div className="fixed bottom-6 right-6">
            <Link to="/home">
              <Button
                onClick={handleSavePractice}
                className="rounded-full px-6 shadow-md"
                disabled={reminderHour === "" || checkInHour === ""}
              >
                Submit
                <ArrowRight size={16} className="ml-2" />
              </Button>
            </Link>
          </div>
        )}
      </div>
    );
  }

  return null;
};

export default NewHabitWizard;
