import React from "react";
import { Practice } from "@/types";
import { useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { toZonedTime, format } from "date-fns-tz";
import { Badge } from "./ui/badge";
import { Area, AreaChart, ResponsiveContainer } from "recharts";
import { ChartContainer, ChartConfig } from "@/components/ui/chart";
import { Label } from "./ui/label";
import { Card } from "./ui/card";

interface ProgressCardProps {
  practice: Practice;
}

const ProgressCard: React.FC<ProgressCardProps> = ({ practice }) => {
  const completions = useQuery(api.completions.get, { practiceId: practice._id });
  const userTimeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;

  const getStreakData = () => {
    if (!completions) return Array(30).fill(false);

    return Array(30)
      .fill(false)
      .map((_, index) => {
        const date = new Date();
        date.setDate(date.getDate() - 29 + index);
        const dateString = format(date, "yyyy-MM-dd", { timeZone: userTimeZone });
        return completions.some((completion) => {
          const completionDate = toZonedTime(completion.date, userTimeZone);
          return format(completionDate, "yyyy-MM-dd", { timeZone: userTimeZone }) === dateString;
        });
      });
  };

  const getChartData = () => {
    if (!completions) return [];

    // Sort completions by date
    const sortedCompletions = [...completions].sort((a, b) => a.date - b.date);

    // Create chart data with effort values
    return sortedCompletions.map((completion, index) => ({
      index: index + 1,
      effort: 10 - completion.effort,
    }));
  };

  const streakData = getStreakData();
  const chartData = getChartData();

  const chartConfig = {
    effort: {
      label: "Effort",
      color: "#a6a679",
    },
  } satisfies ChartConfig;

  return (
    <Card className="overflow-hidden">
      <div className="space-y-2 p-7">
        <Label className="text-primary font-extrabold text-base">{practice.habitName}</Label>
        <h1 className="text-xs font-bold text-stone-600 mr-2">{practice.title}</h1>
      </div>
      <div className="border-t-2 border-dotted border-stone-200" />
      <div className="p-7">
        <h2 className="text-sm pl-1 font-bold mb-4 text-stone-800 uppercase">Progress</h2>
        <div className="grid grid-cols-7 gap-0">
          {["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"].map((day) => (
            <div key={day} className="flex flex-col items-center">
              <span className="text-[10px] text-gray-500 mb-1">{day}</span>
            </div>
          ))}
          {(() => {
            const today = new Date();
            const startDate = new Date(today);
            startDate.setDate(today.getDate() - 90);
            const startDay = startDate.getDay();
            return Array(startDay)
              .fill(null)
              .map((_, i) => <div key={`empty-${i}`} className="flex flex-col items-center"></div>);
          })()}
          {streakData.map((completed, i) => {
            const date = new Date();
            date.setDate(date.getDate() - 90 + i);
            const dayOfWeek = date.toLocaleDateString("en-US", { weekday: "short" });
            const dayOfMonth = date.getDate();
            const isToday = date.toDateString() === new Date().toDateString();

            return (
              <div key={i} className="flex items-center justify-center aspect-square">
                <div
                  className={`flex items-center justify-center w-[75%] h-[75%] max-w-[50px] max-h-[50px] rounded-full text-center ${
                    isToday ? "border-2 border-primary" : ""
                  } ${completed ? "bg-primary text-white" : "bg-secondary/40 text-stone-600"}`}
                  title={`${dayOfWeek}, ${date.toLocaleDateString()}: ${completed ? "Completed" : "Not completed"}`}
                >
                  <span className="text-[10px] font-bold">{dayOfMonth}</span>
                </div>
              </div>
            );
          })}
        </div>
      </div>

      <div className="border-t-2 border-dotted border-stone-200" />
      <div className="p-7">
        <h2 className="text-sm pl-1 font-bold mb-4 text-stone-800 uppercase">Effort Required</h2>
        <div className="rounded-b-xl overflow-hidden">
          {chartData.length > 1 ? (
            <ChartContainer config={chartConfig}>
              <ResponsiveContainer width="100%" height="100%">
                <AreaChart data={chartData} margin={{ top: 0, right: 0, left: 0, bottom: 0 }}>
                  <defs>
                    <linearGradient id="fillEffort" x1="0" y1="0" x2="0" y2="1">
                      <stop offset="5%" stopColor="var(--color-effort)" stopOpacity={0.9} />
                      <stop offset="95%" stopColor="var(--color-effort)" stopOpacity={0.2} />
                    </linearGradient>
                  </defs>
                  <Area type="monotone" dataKey="effort" stroke="var(--color-effort)" fill="url(#fillEffort)" fillOpacity={0.4} />
                </AreaChart>
              </ResponsiveContainer>
            </ChartContainer>
          ) : (
            <div className="flex items-center justify-center h-32 bg-secondary/40 rounded-lg">
              <p className="text-stone-500 font-medium text-xs">No data yet</p>
            </div>
          )}
        </div>
      </div>
    </Card>
  );
};

export default ProgressCard;
