import React, { useEffect, useState } from "react";
import { useParams, useHistory, Link } from "react-router-dom";
import { useMutation, useQuery } from "convex/react";
import { api } from "../../convex/_generated/api";
import { Id } from "../../convex/_generated/dataModel";
import { IonContent, IonPage } from "@ionic/react";
import { Button } from "@/components/ui/button";
import { ArrowLeft, Trash2 } from "lucide-react";
import { Textarea } from "@/components/ui/textarea";
import { Dialog, DialogContent, DialogDescription, DialogFooter, DialogHeader, DialogTitle } from "@/components/ui/dialog";
import { Label } from "@/components/ui/label";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue } from "@/components/ui/select";

const formatHour = (hour: number): string => {
  const period = hour < 12 ? "AM" : "PM";
  const displayHour = hour % 12 || 12;
  return `${displayHour}:00 ${period}`;
};

const PracticePage: React.FC = () => {
  const { id } = useParams<{ id: string }>();
  const history = useHistory();
  const practice = useQuery(api.practices.getById, { id: id as Id<"practices"> });
  const deletePractice = useMutation(api.practices.deletePractice);
  const updatePractice = useMutation(api.practices.update);
  const [title, setTitle] = useState(practice?.title);
  const [reminderHour, setReminderHour] = useState("");
  const [checkInHour, setCheckInHour] = useState("");
  const [isDeleteDialogOpen, setIsDeleteDialogOpen] = useState(false);

  useEffect(() => {
    if (practice?.title) {
      setTitle(practice.title);
    }
    if (practice?.reminderHour) {
      const localReminderHour = (practice.reminderHour + 24 - new Date().getTimezoneOffset() / 60) % 24;
      setReminderHour(localReminderHour.toString());
    }
    if (practice?.checkInHour) {
      const localCheckInHour = (practice.checkInHour + 24 - new Date().getTimezoneOffset() / 60) % 24;
      setCheckInHour(localCheckInHour.toString());
    }
  }, [practice?.title, practice?.reminderHour, practice?.checkInHour]);

  const handleUpdatePractice = () => {
    const updateData: any = { id: id as Id<"practices">, title: title || "" };

    const convertToUTC = (hour: string) => {
      if (hour && hour !== "none") {
        const date = new Date();
        date.setHours(parseInt(hour), 0, 0, 0);
        return date.getUTCHours();
      }
      return undefined;
    };

    const utcReminderHour = convertToUTC(reminderHour);
    const utcCheckInHour = convertToUTC(checkInHour);

    if (utcReminderHour !== undefined) updateData.reminderHour = utcReminderHour;
    if (utcCheckInHour !== undefined) updateData.checkInHour = utcCheckInHour;

    updatePractice(updateData);
  };

  const handleDelete = async () => {
    await deletePractice({ id: id as Id<"practices"> });
    history.push("/");
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className="p-4 h-full max-w-2xl mx-auto">
          <Button variant="ghost" onClick={() => window.history.back()}>
            <ArrowLeft className="w-4 h-4 mr-2" />
            Back
          </Button>
          {practice ? (
            <div className="p-3 h-full space-y-2">
              <div className="flex items-center justify-between">
                <h1 className="text-xl font-bold">{practice.habitName}</h1>
                <Button variant="ghost" size="icon" onClick={() => setIsDeleteDialogOpen(true)}>
                  <Trash2 className="w-5 h-5 text-red-500" />
                </Button>
              </div>
              <Textarea
                className="rounded-xl bg-white h-36"
                value={title}
                onChange={(e) => {
                  setTitle(e.target.value);
                }}
              />
              <div className="space-y-2 pt-4">
                <Label className="text-md font-semibold">Reminder Time</Label>
                <Select onValueChange={setReminderHour} value={reminderHour}>
                  <SelectTrigger className="bg-white">
                    <SelectValue placeholder="Select Reminder Time" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="none">No reminder</SelectItem>
                    {Array.from({ length: 24 }, (_, i) => (
                      <SelectItem key={i} value={i.toString()}>
                        {formatHour(i)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="space-y-2 pt-4">
                <Label className="text-md font-semibold">Check In Time</Label>
                <Select onValueChange={setCheckInHour} value={checkInHour}>
                  <SelectTrigger className="bg-white">
                    <SelectValue placeholder="Select Check In Time" />
                  </SelectTrigger>
                  <SelectContent>
                    <SelectItem value="none">No check in</SelectItem>
                    {Array.from({ length: 24 }, (_, i) => (
                      <SelectItem key={i} value={i.toString()}>
                        {formatHour(i)}
                      </SelectItem>
                    ))}
                  </SelectContent>
                </Select>
              </div>
              <div className="flex justify-end pt-4">
                <Link to="/home">
                  <Button className="rounded-full px-6" onClick={handleUpdatePractice}>
                    Save
                  </Button>
                </Link>
              </div>
              <div className="h-[100px]" />
            </div>
          ) : (
            <div>Loading...</div>
          )}
        </div>
      </IonContent>
      <Dialog open={isDeleteDialogOpen} onOpenChange={setIsDeleteDialogOpen}>
        <DialogContent>
          <DialogHeader>
            <DialogTitle className="text-left leading-6">Are you sure you want to delete this practice?</DialogTitle>
            <DialogDescription className="text-left leading-5">
              This action cannot be undone. This will permanently delete your practice.
            </DialogDescription>
          </DialogHeader>
          <DialogFooter>
            <div className="flex justify-between">
              <Button variant="secondary" onClick={() => setIsDeleteDialogOpen(false)}>
                Cancel
              </Button>
              <Button variant="destructive" onClick={handleDelete}>
                Delete
              </Button>
            </div>
          </DialogFooter>
        </DialogContent>
      </Dialog>
    </IonPage>
  );
};

export default PracticePage;
